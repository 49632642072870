@import url('https://fonts.googleapis.com/css2?family=Staatliches&display=swap');

.portfolio {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* padding-right: 10rem;
    padding-left: 10rem; */
    
}
.portfolio .projects {
    /* height: 88vh;
    max-height: 88vh; */
    height: 100%;
    width: 100%;
    overflow: auto;
    padding-right: 10rem;
    padding-left: 10rem;
    box-sizing: content-box;
}
.projects h1 {
    padding-top: 5rem;
    color: white;
    font-family: 'Staatliches', cursive;
}

.projectLink {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 2.5rem
}

.projectLink div{
    background-color: rgb(0, 0, 0, 0.5);

}

#tictactoe {
    padding: 1rem;

}

#tictactoe .image{
    background: url(../files/tictactoe.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 20rem;
    height: 15rem;
    border: 3px solid black;
}

#onlypets {
    padding: 1rem;
    
}

#onlypets .image{
    background: url(../files/onlyPets.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 20rem;
    height: 15rem;
    border: 3px solid black;
}

#akaizakaya {
    padding: 1rem;
    
}

#akaizakaya .image{
    background: url(../files/akaIzakaya.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 20rem;
    height: 15rem;
    border: 3px solid black;
}

#taskmaster {
    padding: 1rem;
    
}

#taskmaster .image{
    background: url(../files/taskMaster.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 20rem;
    height: 15rem;
    border: 3px solid black;
}


.title  h3{
    color: white;
    margin-top: 0;
    margin-bottom: 0.5rem;
    text-align: left;
    font-family: 'Staatliches', cursive;
    font-size: 25px;
}
.links{
    margin-top: 0.5rem;
}
.links a{
    padding: 1rem;
    color: white;
    text-decoration: none;
    font-weight: bold;
    font-family: 'Staatliches', cursive;
    font-size: 20px;
}
.links a:hover{
    color: aqua;
}
